import React, { useEffect, useState } from 'react';

import { Droppable } from 'react-drag-and-drop';

import { styled } from "@mui/system";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ticketingService from '../../services/ticketingService';

import DraggableNumber from './DraggableNumber';


const EmployeeListPanelContainer = styled("div")({
    padding: "16px",
    borderRadius: "4px",
    backgroundColor: "#f2f2f2",
    boxShadow: "0 0 4px rgba(0, 0, 0, 0.2)",
});

const EmployeeName = styled("h1")({
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "8px"
});

const EmployeeList = styled("ul")({
    listStyle: "none",
    margin: "0",
    padding: "0"
});

const EmployeeListItem = styled("li")({
    marginBottom: "8px"
});

// Employee dashboards/panels component
const EmployeeListPanel = ({ employee, updateTicketState }) => {

    // Current state of tickets picked up by the employee
    const [empProgressList, setEmpProgressList] = useState([])

    const droppableStyle = {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    }

    const employeeStyle = {
        margin: "1%"
    }

    useEffect(() => {
        ticketingService.getTicketsInProgress().then(res => {
            if (res !== "Nema pronađenih ticketa")
                setEmpProgressList(res.filter(r => r.id_worker === employee.id))
        }).catch(err => {
            toast.error("Greška u radu: nije moguće učitati brojeve")
            console.log(err)
        })
    }, [])

    const handleDrop = ({ customer }, event) => {
        ticketingService.getAllTickets().then(res => {
            let current = res.filter(t => parseInt(t.number) === parseInt(customer))
            ticketingService.assignTask(employee.id, current[0].id).then(res => {
                ticketingService.getTicketsInProgress().then(res => {
                    setEmpProgressList(res.filter(r => r.id_worker === employee.id))
                    updateTicketState()
                }).catch(err => {
                    toast.error("Greška u radu: nije moguće učitati brojeve")
                    console.log(err)
                })
            }).catch(err => {
                toast.error("Greška u radu: nije moguće dodijeliti broj odabranom korisniku")
                console.log(err)
            })

        }).catch(err => {
            toast.error("Greška u radu: nije moguće učitati brojeve")
            console.log(err)
        })


    }

    // Return ticket back to pool to choose from
    const quitTicket = (number) => {
        ticketingService.getAllTickets().then(res => {
            let current = res.filter(t => parseInt(t.number) === parseInt(number))
            ticketingService.cancelTask(employee.id, current[0].id).then(res => {
                setEmpProgressList(empProgressList.filter(e => e.number !== number))
                updateTicketState()
            }).catch(err => {
                toast.error("Greška u radu: nije moguće otkazati odabrani broj")
                console.log(err)
            })

        }).catch(err => {
            toast.error("Greška u radu: nije moguće otkazati odabrani broj")
            console.log(err)
        })

    }

    const removeEmployeePanel = () => {
        // TODO
    }

    // Close the ticket assigned to user or remove it from standby
    const closeOrRemove = (number) => {
        ticketingService.getAllTickets().then(res => {
            let current = res.filter(t => parseInt(t.number) === parseInt(number))
            ticketingService.deleteTicket(current[0].id).then(res => {
                setEmpProgressList(empProgressList.filter(e => e.number !== number))
                updateTicketState()
            }).catch(err => {
                toast.error("Greška u radu: nije moguće zaključiti broj")
                console.log(err)
            })

        }).catch(err => {
            toast.error("Greška u radu: nije moguće učitati brojeve")
            console.log(err)
        })
    }

    return (
        <EmployeeListPanelContainer>
            <ToastContainer />
            <Droppable types={["customer"]} onDrop={handleDrop}>
                <div style={droppableStyle}>
                    <div style={employeeStyle}>
                        <EmployeeName>{employee.name}</EmployeeName>
                    </div>

                    <EmployeeList>
                        {empProgressList.map(el => (
                            <EmployeeListItem key={el}>
                                <DraggableNumber number={el.number} list={empProgressList} quitTicket={quitTicket} closeOrRemove={closeOrRemove} />
                            </EmployeeListItem>
                        ))}
                    </EmployeeList>

                </div>
            </Droppable>
        </EmployeeListPanelContainer>
    );
}

export default EmployeeListPanel
