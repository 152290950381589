import React from 'react';

import { Button } from '@mui/material';
import { styled } from '@mui/system';
import { useParams } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const PickNumberContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: '60%',
});


const PickNumber = () => {
    let { number } = useParams()
    return (
        <PickNumberContainer>
            <h3>VAŠ BROJ U REDU ČEKANJA</h3>
            <h1>{number}</h1>
        </PickNumberContainer>
    );
};

export default PickNumber;
