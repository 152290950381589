import React from 'react';

import { Droppable } from 'react-drag-and-drop';

import { styled } from "@mui/system";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ticketingService from '../../services/ticketingService';

import DraggableNumber from './DraggableNumber';


const StandbyListPanelContainer = styled("div")({
    padding: "16px",
    borderRadius: "4px",
    backgroundColor: "#f2f2f2",
    boxShadow: "0 0 4px rgba(0, 0, 0, 0.2)"
});

const StandbyListTitle = styled("h1")({
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "8px"
});

const StandbyList = styled("ul")({
    listStyle: "none",
    margin: "0",
    padding: "0"
});

const StandbyListItem = styled("li")({
    marginBottom: "8px"
});

// Handles whatever appears or drops onto standby list
const StandbyListPanel = ({ standbyList, updateTicketState }) => {

    // If ticket is returned (an employee has decided to quit ticket and return it to the pool of standby list numbers)
    const handleDrop = ({ customer }, event) => {
        updateTicketState()
    }

    // Ticket is being removed from the standby list and storage entirely
    const closeOrRemove = (number) => {

        ticketingService.getAllTickets().then(res => {
            let current = res.filter(t => parseInt(t.number) === parseInt(number))
            ticketingService.deleteTicket(current[0].id).catch(err => {
                {
                    toast.error("Greška u radu: nije obrisati broj")
                    console.log(err)
                }
            })
            updateTicketState()

        }).catch(err => {
            toast.error("Greška u radu: nije moguće učitati brojeve")
            console.log(err)
        })


    }

    return (
        <StandbyListPanelContainer>
            <ToastContainer />
            <Droppable types={["customer"]} onDrop={handleDrop}>
                <div>
                    <StandbyListTitle>U najavi</StandbyListTitle>
                    <StandbyList>
                        {standbyList.map(el => (
                            <StandbyListItem key={el}>
                                <DraggableNumber number={el} list={[]} setList={() => { }} closeOrRemove={closeOrRemove} />
                            </StandbyListItem>
                        ))}
                    </StandbyList>
                </div>
            </Droppable>
        </StandbyListPanelContainer>
    );
}

export default StandbyListPanel
