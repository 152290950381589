import React from 'react';

import { Container } from '@mui/material';

import PickNumber from './PickNumber';

// Main component for user interaction - number picks
const NumberPickerComponent = ({ generatedNumber, listInStandby, setListInStandby, setGeneratedNumber }) => {
  
  const styles = {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 'calc(100vh - 96px)',
      paddingTop: '48px',
      paddingBottom: '48px',
    },
  };

  return (
    <Container maxWidth="md" style={styles.root}>
      <PickNumber
        generatedNumber={generatedNumber}
        listInStandby={listInStandby}
        setListInStandby={setListInStandby}
        setGeneratedNumber={setGeneratedNumber} />
    </Container>
  );
};

export default NumberPickerComponent;
