import React from 'react';

import { Draggable } from 'react-drag-and-drop';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';

const StyledBox = styled(Box)({
    backgroundColor: '#2196f3',
    width: "1vw",
    height: "1vw",
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    marginRight: '10px',
});

// Draggable component to drag/drop from one panel to another
const DraggableNumber = ({ number, list, setList, quitTicket, closeOrRemove }) => {
    const draggableStyle = {
        display: "flex",
        flexDirection: "row"
    }

    // If drag is detected from employee panel (ticket currently in progress), try to quit it
    const handleDragEnd = (e) => {
        try { quitTicket(number) } catch (err) { console.log(err) }
    }

    // If close button is clicked
    const closeOrRemoveTicket = () => {
        closeOrRemove(number);
    };

    return (
        <div>
            <Draggable type="customer" data={number} onDragEnd={handleDragEnd} style={draggableStyle}>
                <StyledBox>{number}</StyledBox>
                <Button variant="contained" onClick={closeOrRemoveTicket}>
                    x
                </Button>
            </Draggable>
        </div>
    );
};

export default DraggableNumber;
