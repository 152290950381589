import axios from "axios";

const API_URL = "https://chupchup.hr/chup_api"
const PRINTER_URL = "http://192.168.0.105:9100/WebPrintSDK"

const assignTask = async (worker, ticket) => {
    const response = await axios.get(`${API_URL}/assignTask.php?workerid=${worker}&ticketid=${ticket}`,)
        .catch(err => { throw err.response })

    return response
}

const cancelTask = async (worker, ticket) => {
    const response = await axios.get(`${API_URL}/cancleTask.php?workerid=${worker}&ticketid=${ticket}`,)
        .catch(err => { throw err.response })

    return response
}

const createTicket = async () => {
    const response = await axios.get(`${API_URL}/createTicket.php`,)
        .catch(err => { throw err.response })

    return response.data
}

const createUser = async (user) => {
    const response = await axios.get(`${API_URL}/createUser.php?username=${user}`,)
        .catch(err => { throw err.response })

    return response
}

const deleteTicket = async (id) => {
    const response = await axios.get(`${API_URL}/deleteTicket.php?id=${id}`,)
        .catch(err => { throw err.response })

    return response
}

const finishTask = async (worker, ticket) => {
    const response = await axios.get(`${API_URL}/finishTask.php?workerid=${worker}&ticketid=${ticket}`,)
        .catch(err => { throw err.response })

    return response
}

const getAllTickets = async () => {
    const response = await axios.get(`${API_URL}/getAllTickets.php`)
        .catch(err => { throw err.response })

    return response.data
}

const getTicketsInProgress = async () => {
    const response = await axios.get(`${API_URL}/getTicketsInProgress.php`)
        .catch(err => { throw err.response })
    return response.data
}

const getUsers = async () => {
    const response = await axios.get(`${API_URL}/getUsers.php`)
        .catch(err => { throw err.response })
    return response.data
}

export default {
    assignTask,
    cancelTask,
    createTicket,
    createUser,
    deleteTicket,
    finishTask,
    getAllTickets,
    getTicketsInProgress,
    getUsers,
    PRINTER_URL
}