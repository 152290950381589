import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';

import TicketPage from './components/NumberPicker/TicketPage';
import NumberPickerComponent from './components/NumberPicker/NumberPickerComponent';
import StateDisplayComponent from './components/StateDisplay/StateDisplayComponent';
import AdminPanelComponent from './components/AdminPanel/AdminPanelComponent';
import LandingScreenComponent from './components/LandingScreen/LandingScreenComponent';


function App() {
  const [generatedNumber, setGeneratedNumber] = useState(0);
  const [listInStandby, setListInStandby] = useState([])
  const [listInProgress, setListInProgress] = useState([])
  const [employees, setEmployees] = useState([])

  return (
    <div>
     
        <BrowserRouter>
          <Routes>
            <Route path="/pick/:number" element={<TicketPage />} />

            <Route path="/pick" element={
              <NumberPickerComponent
                generatedNumber={generatedNumber}
                listInStandby={listInStandby}
                setListInStandby={setListInStandby}
                setGeneratedNumber={setGeneratedNumber} />} />

            <Route path="/state" element={
              <StateDisplayComponent
                listInStandby={listInStandby}
                listInProgress={listInProgress}
                setListInProgress={setListInProgress}
                setListInStandby={setListInStandby} />} />

            <Route path="/admin" element={
              <AdminPanelComponent
                listInStandby={listInStandby}
                listInProgress={listInProgress}
                employees={employees}
                setListInStandby={setListInStandby}
                setListInProgress={setListInProgress}
                setEmployees={setEmployees} />} />

            <Route path="/" element={<LandingScreenComponent />} />
          </Routes>

        </BrowserRouter>


    </div>
  );
}

export default App;
