import React from 'react';

import { Button } from '@mui/material';
import { styled } from '@mui/system';

import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import QRCode from 'react-qr-code'

import ticketingService from '../../services/ticketingService';
import { requestPrint } from '../../utility/bxlcommon'
import { useState } from 'react';


const PickNumberButton = styled(Button)({
    backgroundColor: '#1976d2',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#115293',
    },
});

const PickNumberContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '24px',
});

const InfoMessage = styled('p')({
    textAlign: 'center'
})

const QRCodeScanBox = styled('div')({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
})

const PrintHide = styled('div')({
    '@media print': {
        display: 'none',
    },
});

const PickNumber = ({ listInStandby, setListInStandby, setGeneratedNumber }) => {
    const [QRLink, setQRLink] = useState("")
    const [showQR, setShowQR] = useState(false)

    // Generates a new ticket and updates storage
    const generateANewNumber = () => {
        ticketingService.createTicket().then(res => {
            setGeneratedNumber(parseInt(res.name))
            setListInStandby(listInStandby.concat(parseInt(res.name)))
            printQRCode(res.name)
        }).catch(err => {
            //toast.error("Greška u radu: nije moguće generirati broj")
            console.log(err)
        })
        
    };

    const printQRCode = (num) => {
        setQRLink(`https://chupchup.hr/chup_api/getCreatedTicket.php?number=${num}`)
        setShowQR(true)
    }

    /* const printNumber = async (num) => {
        let ticketNumber = `\nVAS BROJ U REDU CEKANJA JE ${num}\n\n\n\n\n\n\n\n`
        requestPrint(ticketingService.PRINTER_URL, ticketNumber, () => console.log("printed"))
        setTimeout(() => { window.location.reload() }, 60000)
    } */

    const confirmTicket = () => {
        console.log(false)
        setShowQR(false)
    }

    return (
        <PickNumberContainer>
            <ToastContainer />
            <PrintHide>
                <QRCodeScanBox>
                    {showQR &&
                        <InfoMessage>
                            <h2>Skenirajte i potvrdite vaš broj</h2>
                        </InfoMessage>}

                    {showQR && <QRCode value={QRLink} size={250} />}
                </QRCodeScanBox>

                <PickNumberContainer>
                    {!showQR && <PickNumberButton variant='contained' onClick={generateANewNumber}>
                        Uzmi broj u redu čekanja
                    </PickNumberButton>}

                    {showQR && <PickNumberButton variant='contained' onClick={confirmTicket}>
                        Potvrdi broj čekanja
                    </PickNumberButton>}
                </PickNumberContainer>

            </PrintHide>
        </PickNumberContainer>
    );
};

export default PickNumber;
