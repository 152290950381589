import React, { useEffect, useState } from 'react';
import { styled } from "@mui/system";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ticketingService from '../../services/ticketingService';

import StandbyListPanel from './StandbyListPanel';
import EmployeeListPanel from './EmployeePanel';
import AddNewEmployeePanel from './AddNewEmployeePanel';

const AdminPanelContainer = styled("div")({
    width: "90%",
    padding: "16px",
});

const ModalButton = styled("input")({
    border: "none",
    borderRadius: "4px",
    backgroundColor: "#f2f2f2",
    padding: "8px 16px",
    cursor: "pointer",
    fontWeight: "bold",
    marginBottom: "16px",
    marginTop: "16px",
    boxShadow: "0 0 4px rgba(0, 0, 0, 0.2)",
    "&:hover": {
        backgroundColor: "#e6e6e6"
    }
});

// Main admin panel component, contains all else
const AdminPanelComponent = ({ listInStandby, listInProgress, setListInStandby, setListInProgress, employees, setEmployees }) => {
    const [open, setOpen] = useState(false)

    /* const printNumber = (number) => {
        console.log(`I will print out ${number}`)
    }

    const checkPrintUpdates = (previousState, currentState) => {
        if (previousState.length > currentState.length) return
        else if (previousState.length < currentState.length) {
            printNumber(currentState[length -1])
        }
        else if (previousState.length == currentState.length) {
            let prev = previousState[length - 1]
            let curr = currentState[length - 1]
            if (prev !== curr) {
                printNumber(curr)
            }
        }
    } */

    // Updates states related to standby and progress tickets
    const updateTicketState = () => {
        ticketingService.getAllTickets().then(res => {
            if (res === "Nema pronađenih ticketa") {
                setListInStandby([])
                setListInProgress([])
                return
            }

            let allTickets = res.map(num => parseInt(num.number))
            ticketingService.getTicketsInProgress().then(res => {
                if (res !== "Nema pronađenih ticketa") {
                    let takenTickets = res.map(t => parseInt(t.number))
                    let standbyTickets = allTickets.filter(a => !takenTickets.includes(a))
                    setListInProgress(takenTickets)
                    setListInStandby(standbyTickets)
                }

                else {
                    setListInStandby(allTickets)
                }

            }).catch(err => {
                toast.error("Greška u radu: nije moguće učitati brojeve")
                console.log(err)
            })

        }
        ).catch(err => {
            toast.error("Greška u radu: nije moguće učitati brojeve")
            console.log(err)
        })
    }

    // Update this each 3000ms for new db changes
    useEffect(() => {
        ticketingService.getUsers().then(res => {
            setEmployees(res)
        }).catch(err => {
            toast.error("Greška u radu: nije moguće učitati korisnike")
            console.log(err)
        })

        updateTicketState()

        let rotationInterval = setInterval(() => {
            updateTicketState()

        }, 3000)

        return () => {
            clearInterval(rotationInterval)
        }

    }, [])

    const openDialog = () => { setOpen(!open) }

    return (
        <AdminPanelContainer>

            <ToastContainer />

            <StandbyListPanel
                standbyList={listInStandby}
                updateTicketState={updateTicketState} />


            <ModalButton defaultValue="Dodaj novog zaposlenika" onClick={openDialog} />
            {open && <AddNewEmployeePanel employees={employees} setEmployees={setEmployees} />}


            {employees.map((emp, i) => (
                <EmployeeListPanel
                    key={i}
                    employee={emp}
                    updateTicketState={updateTicketState}
                />
            ))}
        </AdminPanelContainer>

    );
}

export default AdminPanelComponent;
