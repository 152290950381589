import React, { useState } from 'react';

import { Button } from '@mui/material';
import { styled } from '@mui/system';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ticketingService from '../../services/ticketingService';

// Component to generate a new employee panel
const AddNewEmployeePanel = ({ setEmployees }) => {
    const [employeeName, setEmployeeName] = useState("")

    const handleEmployeeName = (e) => {
        setEmployeeName(e.target.value)
    }

    // Create new user and set list of employees
    const handleForm = (e) => {
        e.preventDefault();
       
        ticketingService
            .createUser(employeeName)
            .then(res => {
                ticketingService.getUsers().then(res => {
                    setEmployees(res)
                    toast.success("Uspješno generiran novi korisnik")
                }).catch(err => {
                    toast.error("Greška u radu: nije moguće učitati korisnike")
                    console.log(err)
                })
            })
            .catch(err => {
                toast.error("Greška u radu: nije moguće spremiti novog korisnika")
                console.log(err)
            })
    };

    const StyledForm = styled('form')({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '10px',
    });

    const StyledInput = styled('input')({
        display: 'flex',
        padding: "1%"
    })

    const StyledButton = styled(Button)({
        background: 'green',
        color: 'white',
        '&:hover': {
            background: 'darkgreen',
        }
    });

    return (
        <div>
            <ToastContainer />
            <StyledForm onSubmit={handleForm}>
                <StyledInput type='text' placeholder='Unesi ime...' onChange={handleEmployeeName} value={employeeName} autoFocus />
                <StyledButton type='submit'>Dodaj novi panel</StyledButton>
            </StyledForm>
        </div>
    );
};

export default AddNewEmployeePanel;
