import React from 'react';
import { Link } from "react-router-dom";
import { Typography, Button, Container, Box, Grid } from '@mui/material';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  header: {
    paddingTop: '48px',
    paddingBottom: '16px',
    textAlign: 'center',
  },
  nav: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '48px',
    marginBottom: '48px',
  },
  link: {
    margin: '16px',
  },
};

const LandingScreenComponent = () => {
  return (
    <div style={styles.root}>
      <header style={styles.header}>
        <Typography variant="h2" component="h1" gutterBottom>
          Početna
        </Typography>
      </header>
      <nav style={styles.nav}>
        <Link style={styles.link} to="/pick">
          <Button variant="contained" color="primary">
            Ekran odabira broja
          </Button>
        </Link>
        <Link style={styles.link} to="/state">
          <Button variant="contained" color="primary">
            Ekran stanja čekanja
          </Button>
        </Link>
        <Link style={styles.link} to="/admin">
          <Button variant="contained" color="primary">
            Admin panel
          </Button>
        </Link>
      </nav>
    </div>
  );
};

export default LandingScreenComponent;
