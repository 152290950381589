import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/system';

// Container component to display ticket status
const ProcessColumn = ({ title, list, numberColor, detailList }) => {
  const containerStyle = {
    backgroundColor: '#f5f5f5',
  };

  const listStyle = {
    padding: '8px',
    borderRadius: '10px',
    width: '6%',
  };

  const listItemStyle = {
    backgroundColor: numberColor,
    padding: "20%",
    borderRadius: "50%",
    width: "2vw",
    height: "2vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "10%",
  }

  const listItemStyleProgress = {
    padding: "20%",
    width: "7vw",
    height: "2vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "10%",
  }

  const numberStyle = {
    borderRadius: "50%",
    padding: "10%",
    marginRight: "5%",
    backgroundColor: numberColor,
    color: "black",
    fontSize: '10rem',
    '@media (max-width:600px)': {
      fontSize: '9.5rem',
    },
    '@media (max-width:400px)': {
      fontSize: '9rem',
    },
  };


  return (
    <div>{detailList.length !== 0 ?
      
      <Paper style={containerStyle} elevation={2}>
        <Typography variant="h6" component="h1">
          {title}
        </Typography>
        <List style={listStyle}>
          {detailList.map((el) => (
            <ListItem key={el.number} style={ listItemStyleProgress}>
              <ListItemText primary={el.number} sx={numberStyle} />
              <ListItemText primary={el.name} />
            </ListItem>
          ))}
        </List>
      </Paper> : 
      
      <Paper style={containerStyle} elevation={2}>
        <Typography variant="h6" component="h1">
          {title}
        </Typography>
        <List style={listStyle}>
          {list.map((el) => (
            <ListItem key={el} style={listItemStyle}>
              <ListItemText primary={el} sx={numberStyle} />
            </ListItem>
          ))}
        </List>
      </Paper>}</div>
  );
};

export default ProcessColumn;
