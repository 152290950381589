import { useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ticketingService from '../../services/ticketingService';
import ProcessColumn from './ProcessColumn';


// Displays tickets in standby and tickets in progress
const StateDisplayComponent = ({ listInStandby, listInProgress, setListInProgress, setListInStandby }) => {
  const [employeeTickets, setEmployeeTickets] = useState([])

  const checkForTicketUpdates = () => {
    ticketingService.getAllTickets().then(res => {
      if (res === "Nema pronađenih ticketa") {
        setListInStandby([])
        setListInProgress([])
        setEmployeeTickets([])
        return
      }

      let allTickets = res.map(num => parseInt(num.number))
      ticketingService.getTicketsInProgress().then(res => {

        if (res !== "Nema pronađenih ticketa") {
          let takenTickets = res.map(t => parseInt(t.number))
          let standbyTickets = allTickets.filter(a => !takenTickets.includes(a))
          let ticketsWithEmployees = res.filter(r => allTickets.includes(parseInt(r.number)))

          ticketingService.getUsers().then(res => {
            let users = ticketsWithEmployees.map(t => {
              let newUser = {
                number: t.number,
                name: res.find(u => u.id === t.id_worker).name
              }
              return newUser
            })

            setEmployeeTickets(users)
          }).catch(err => console.log(err))

          setListInProgress(takenTickets)
          setListInStandby(standbyTickets)
        }

        else {
          setListInStandby(allTickets)
          setListInProgress([])
          setEmployeeTickets([])
        }
      }).catch(err => {
        toast.error("Greška u radu: nije moguće učitati brojeve")
        console.log(err)
      })
    }
    ).catch(err => {
      toast.error("Greška u radu: nije moguće učitati brojeve")
      console.log(err)
    })
  }

  useEffect(() => {
    checkForTicketUpdates()

    let rotationInterval = setInterval(() => {
      checkForTicketUpdates()
    }, 3000)

    return () => {
      clearInterval(rotationInterval)
    }
  }, [])

  return (
    <div>
      <ToastContainer />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <ProcessColumn title="U najavi" list={listInStandby} numberColor={"#FFC107"} detailList={[]} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProcessColumn title="U obradi" list={listInProgress} numberColor={"#28A745"} detailList={employeeTickets} />
        </Grid>
      </Grid>
    </div>
  );
}

export default StateDisplayComponent;
